import styled, { css } from "styled-components";

const Button = styled(
    ({
         children,
         disabled = false,
         onClick = () => {},
         ...props
     }) => (
        <button
            {...props}
            type='button'
            onClick={!disabled ? onClick : () => {}}
        >
            {children}
        </button>
    )
)`
    user-select: none;
    cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    width: fit-content;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 22px;
    letter-spacing: 0.36px;

    ${(props) =>
            props.disabled
                    ? css`
                  background-color: #ccc;
                  color: #666;
                  pointer-events: none;
              `
                    : css`
                  background-color: rgb(245, 101, 12);
                  color: #ffffff;
              `}

    &:hover {
        ${(props) =>
                !props.disabled &&
                css`
            background-color: #f56512;
        `}
    }
`;

export default Button;