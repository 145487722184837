import './App.css';
import Header from "./components/Header";
import Banner from "./blocks/Banner";
import About from "./blocks/About";
//import Demo from "./blocks/Demo";
import CommercialOffer from "./blocks/CommercialOffer";
import Portfolio from "./blocks/Portfolio";
import Video from "./blocks/Video";
import Tasks from "./blocks/Tasks";
import Stages from "./blocks/Stages";
import Form from "./blocks/Form";
import ModalWindow from "./blocks/ModalWindow";
import useModal from "./Hooks";
import Footer from "./components/Footer/Footer";


function App() {
    const {isOpen} = useModal();

  return (
    <div className="App">
        <Header />

        {isOpen && <ModalWindow />}

        <Banner />
        <About />
        {/*<Demo />*/}
        <CommercialOffer />
        <Portfolio />
        <Video />
        <Tasks />
    {/*    <Stages />*/}
        <Form />
        <Footer />
    </div>
  );
}

export default App;
