import React, {useState} from "react";
import PortfolioPanel from "./styled";


const Portfolio = () => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePanel = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='portfolio'>

            <div className='portfolioBlock'>
                <PortfolioPanel onClick={togglePanel} isOpen={isOpen}>Посмотреть примеры работ</PortfolioPanel>

                <h1 className='portfolioTitle'>мы выполняем:</h1>

                <div className='portfolioText'>
                    <ul>
                        <li>3D визуализация архитектуры;</li>
                        <li>Визуализация жилых комплексов;</li>
                        <li>Визуализация дома;</li>
                        <li>Визуализация коттеджа;</li>
                    </ul>
                </div>

                <div className='portfolioText'>
                    <ul>
                        <li>3D моделирование здания;</li>
                        <li>Рекламную графику для печати;</li>
                        <li>Визуализация 3D планировок;</li>
                        <li>Дизайн типовых квартир ЖК;</li>
                    </ul>
                </div>
            </div>

            <div className='portfolioImg'>
                <img className='portfolioBanner' src='images/portfolio.jpg'/>
            </div>

        </div>
    )
}


export default Portfolio