import styled from 'styled-components';

export const FooterContainer = styled.footer`
    background-color: #2d2d2d;
    color: #fff;
    padding: 40px 0;
`;

export const FooterContent = styled.div`
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`;

export const ContactInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    a {
        color: #fff;
        text-decoration: none;
        transition: color 0.3s ease;
        padding-left: 20px;

        &:hover {
            color: #f56512;
        }
    }
`;

export const Copyright = styled.div`
    font-size: 14px;
    color: #999;
    text-align: right;

    a {
        color: #f56512;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
            color: #fff;
        }
    }
`;