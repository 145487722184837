import React from "react";


const CommercialOffer = () => {
    return (
        <div className='offer'>
            <div className='commercialOfferImg'>
                <img className='commercialOfferBanner' src='images/apartment.png'/>
            </div>

            <div className='commercialOffer'>
                <h1 className='commercialOfferTitle'>отличный инструмент для презентации архитектурных проектов</h1>

                <div className='commercialOfferText'>
                    <p className='text'>Проведите привлекательные презентации архитектурных проектов. Это современное решение, позволяющее продемонстрировать вашу разработку в выгодном свете: добавьте в интерактивную 3D-модель столько деталей, сколько пожелаете, чтобы создать полное погружение для зрителя.</p>
                </div>

                <h1 className='commercialOfferTitle'>идеальный способ продать недвижимость придирчивым клиентам</h1>

                <div className='commercialOfferText'>
                    <p className='text'>Рекламируйте коммерческую или жилую недвижимость с помощью сенсорного дисплея и удобной программы. Больше никаких громоздких моделей - возьмите с собой презентационные материалы куда угодно - на выставки, в офисы продаж или даже к дверям клиентов.</p>
                </div>
            </div>
        </div>
    )
}


export default CommercialOffer