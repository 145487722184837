import React, {useEffect, useState} from "react";
import NavMenu from "../NavMenu";


const Header = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.pageYOffset);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`header ${scrollPosition > 0 ? 'header--colored' : ''}`}>
            <img className='logo' src='images/art.png' alt="" />
            <NavMenu />
        </div>
    )
}


export default Header