import React from "react";


const Banner = () => {
    return (
        <div className='banner'>
            <img className='bannerImg' src='images/banner.jpg' />
            <h1 className='bannerTitle'>УВЕЛИЧЕНИЕ ПРОДАЖИ НЕДВИЖИМОСТИ С ПОМОЩЬЮ АРХИТЕКТУРНОЙ ВИЗУАЛИЗАЦИИ</h1>
        </div>
    )
}


export default Banner