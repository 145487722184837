import React from "react";
import { TASKS } from '../../dummy'
import useModal from "../../Hooks";


const Tasks = () => {
    return (
        <div className='tasks'>
            {TASKS.map(p => (
                <Task title={p.title} desc={p.desc} foolDesk={p.foolDesk} />
            ))}
        </div>
    )
}


const Task = ({ title, desc, foolDesk }) => {
    const {isOpen, openModal, setTitleModal, setDescModal} = useModal();

    const hendleModal = () => {
        if (!isOpen) {
            setTitleModal(title);
            setDescModal(foolDesk);
            openModal();
        }
    }

    return (
        <div className='taskBlock' onClick={() => hendleModal()}>
            <div className='taskTitle'>{title}</div>
            <div className='dummyDesc'>{desc}</div>
        </div>
    )
}


export default Tasks