import styled from "styled-components";

export const InputsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
`;

export const CorrectMessage = styled.div`
    display: flex;
    align-items: center;
    color: rgb(245, 101, 12);
    margin-top: 8px;
`;

export const ErrorMessage = styled.div`
    display: flex;
    color: red;
    margin-top: 8px;
    width: 85%;
`;

export const CheckmarkIcon = styled.span`
    font-size: 18px;
    margin-right: 8px;
    animation: checkmarkAnimation 0.3s ease-in-out;

    @keyframes checkmarkAnimation {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
`;

export const CrossIcon = styled.span`
    font-size: 18px;
    margin-right: 8px;
    animation: crossAnimation 0.3s ease-in-out;

    @keyframes crossAnimation {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(90deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }
`;

export const FilterCheckbox = styled.div`
    display: flex;
    margin-top: 20px;

    input[type='checkbox'] {
        appearance: none;
        width: 18px;
        height: 18px;
        border: 2px solid;
        border-radius: 4px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: border-color 0.3s ease;

        &:before {
            content: '\\2713';
            color: transparent;
            font-size: 14px;
            transition: color 0.3s ease;
        }

        &:checked {
            border-color: #f56512;

            &:before {
                color: #f56512;
            }
        }

        &:hover {
            border-color: #f56512;
        }
    }
`;

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: end;
    gap: 12px;
`;

export const FileIcon = styled.span`
    font-size: 24px;
    margin-right: 8px;
`;

export const FileLabel = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(45, 45, 45);
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: rgb(245, 101, 12);
    }
`;

export const FormHeader = styled.div`
    text-align: center;
    margin-bottom: 40px;

    h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    p {
        font-size: 16px;
        color: #666;
    }
    
    .bold {
        font-weight: 700;
    }
`;