import React from "react";


const About = () => {
    return (
        <div className='about'>
            <div className='aboutUse'>
                <h1 className='aboutUseTitle'>архитектурная визуализация</h1>

                <div className='aboutUseText'>
                    <p className='text'>Наша компания специализируется на использовании передовых технологий нейронных сетей для создания архитектурной реальности. Благодаря этим иновационным методам, ма можем предоставить вам услуги 3D визуализации архитектуры, интерьеров и создания презентационных 3D видео в разы быстрее и выгоднее.</p>
                    <p className='text'>Мы можем создать детальную модель вашего проекта и представить ее со всех сторон, чтобы вы могли оценить, насколько гармонично она будет вписываться в окружающую среду.</p>
                    <p className='text'>Сотрудничая с нами, вы можете быть уверены, что ваш проект будет выполнен эффективно и в кратчайшие сроки.</p>
                </div>
            </div>

            <div className='aboutUseImg'>
                <img className='aboutBanner' src='images/Build_gif.gif'/>
            </div>
        </div>
    )
}


export default About