import React from "react";


const NavMenu = () => {
    return (
        <div className='navMenu'>
            <a className='mail' href="mailto:arttelstudio@inbox.com">arttelstudio@inbox.com</a>
            <a className='tel' href="#">заказать звонок</a>
            <a className='formLink' href="#">онлайн заявка</a>
        </div>
    )
}


export default NavMenu