import { useState } from 'react';

const useModal = () => {
    const [isOpen, setIsOpen] =useState(false);
    const [titleMod, setTitle] =useState("");
    const [urlMod, setUrl] =useState("");
    const [descMod, setDesc] =useState("");

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const setTitleModal = (title) => {
        setTitle(title)
    }

    const setUrlModal = (url) => {
        setUrl(url)
    }

    const setDescModal = (desc) => {
        setDesc(desc)
    }

    return {isOpen, openModal, closeModal, titleMod, setTitleModal, urlMod, setUrlModal, descMod, setDescModal};
};

export default useModal;