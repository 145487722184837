import React, { useState } from "react";
import { Formik } from 'formik';
import Button from "../../components/Button";
import InputMask from 'react-input-mask'
import {
    CheckmarkIcon,
    CorrectMessage,
    CrossIcon,
    ErrorMessage, FileIcon, FileLabel, FilterCheckbox,
    InputsWrapper, FormHeader,
    InputWrapper
} from "./styled";

const Form = () => {
    const [fileLoaded, setFileLoaded] = useState(false);

    return (
        <div className='form'>
            <FormHeader>
                <p>ЕСЛИ ВАС ИНТЕРЕСУЕТ 3D ВИЗУАЛИЗАЦИЯ, ПРОСТО ПРЕДОСТАВЬТЕ НАМ ПЛАН ПОМЕЩЕНИЯ И ПРИМЕРЫ ОЖИДАЕМОГО
                    РЕЗУЛЬТАТА.</p>
                <p className='bold'>В ДАЛЬНЕЙШЕМ МЫ ОБСУДИМ ВСЕ ДЕТАЛИ ПРОЕКТА, ЧТОБЫ ТОЧНО ПОНЯТЬ ВАШИ ПОТРЕБНОСТИ И ЖЕЛАНИЯ.</p>
            </FormHeader>

            <Formik
                initialValues={{name: '', phone: '', email: '', message: '', file: null, consent: false }}
                validate={values => {
                    const errors = {};

                    if (!values.name) {
                        errors.name = 'Введите имя';
                    } else if (!/^[а-яА-ЯёЁ\s]+$/.test(values.name)) {
                        errors.name = 'Имя должно содержать только русские буквы';
                    }

                    if (!values.phone) {
                        errors.phone = 'Введите телефон';
                    } else if (!/^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/.test(values.phone)) {
                        errors.phone = 'Неверный формат телефона';
                    }

                    if (!values.email) {
                        errors.email = 'Введите адрес электронной почты';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Неверный адрес почты';
                    }

                    if (!values.consent) {
                        errors.consent = 'Необходимо согласие на обработку персональных данных';
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form className='formBlock' onSubmit={handleSubmit}>
                        <FormHeader>
                            <h3>Свяжитесь с нами</h3>
                            <p>Для того чтобы заказать смету, оформите заявку обратной связи. Если у Вас остались вопросы по стоимости и срокам, напишите на почту: info@artell.studio</p>
                        </FormHeader>

                        <InputsWrapper>
                            <InputWrapper>
                                {errors.name && touched.name ? (
                                    <ErrorMessage>
                                        <CrossIcon>&#10006;</CrossIcon>
                                        {errors.name}
                                    </ErrorMessage>
                                ) : values.name && touched.name ? (
                                    <CorrectMessage>
                                        <CheckmarkIcon>&#10003;</CheckmarkIcon>
                                        Имя введено верно
                                    </CorrectMessage>
                                ) : null}

                                <input
                                    className='valueForm'
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    style={{ borderColor: errors.name ? 'red' : values.name && touched.name ? 'rgb(245, 101, 12)' : 'rgb(45, 45, 45)' }}
                                    placeholder='Введите имя'
                                />
                            </InputWrapper>
                            <InputWrapper>
                                {errors.phone && touched.phone ? (
                                    <ErrorMessage>
                                        <CrossIcon>&#10006;</CrossIcon>
                                        {errors.phone}
                                    </ErrorMessage>
                                ) : values.phone && touched.phone ? (
                                    <CorrectMessage>
                                        <CheckmarkIcon>&#10003;</CheckmarkIcon>
                                        Телефон введен верно
                                    </CorrectMessage>
                                ) : null}

                                <InputMask
                                    mask="+7 (999) 999-99-99"
                                    className='valueForm'
                                    name="phone"
                                    placeholder="Телефон"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone}
                                    style={{ borderColor: errors.phone ? 'red' : values.phone && touched.phone ? 'rgb(245, 101, 12)' : 'rgb(45, 45, 45)' }}
                                />
                            </InputWrapper>
                            <InputWrapper>
                                {errors.email && touched.email ? (
                                    <ErrorMessage>
                                        <CrossIcon>&#10006;</CrossIcon>
                                        {errors.email}
                                    </ErrorMessage>
                                ) : values.email && touched.email ? (
                                    <CorrectMessage>
                                        <CheckmarkIcon>&#10003;</CheckmarkIcon>
                                        Адрес электронной почты верный
                                    </CorrectMessage>
                                ) : null}

                                <input
                                    className='valueForm'
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    style={{ borderColor: errors.email ? 'red' : values.email && touched.email ? 'rgb(245, 101, 12)' : 'rgb(45, 45, 45)' }}
                                    placeholder='Введите email'
                                />
                            </InputWrapper>
                        </InputsWrapper>
                        <textarea
                            className='textareaValue'
                            name="message"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.message}
                            placeholder="Сообщение"
                        />
                        <InputWrapper>
                            <input
                                type="file"
                                name="file"
                                onChange={(e) => {
                                    handleChange(e);
                                    setFileLoaded(e.target.files.length > 0);
                                }}
                                onBlur={handleBlur}
                                id="file-input"
                                style={{ display: 'none' }}
                            />
                            <FileLabel htmlFor="file-input" className="file-label">
                                {fileLoaded ? (
                                    <>
                                        <CheckmarkIcon>&#10003;</CheckmarkIcon>
                                        Файл загружен
                                    </>
                                ) : (
                                    <>
                                        <FileIcon>&#128206;</FileIcon>
                                        {values.file ? values.file.name : 'Загрузить файл'}
                                    </>
                                )}
                            </FileLabel>
                        </InputWrapper>
                        <FilterCheckbox>
                            <input
                                type="checkbox"
                                name="consent"
                                checked={values.consent}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            Согласие на обработку персональных данных
                        </FilterCheckbox>
                        <Button
                            type="submit"
                            disabled={
                                isSubmitting ||
                                !values.name ||
                                !values.phone ||
                                !values.email ||
                                !values.consent
                            }
                        >
                            Отправить заявку
                        </Button>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default Form;