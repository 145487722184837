import React from "react";
import { ContactInfo, Copyright, FooterContainer, FooterContent } from "./styled";


const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <FooterContainer>
            <FooterContent>
                <ContactInfo>
                    <a href="#">Пользовательское соглашение</a>
                    <a href="mailto:info@artell.studio">info@artell.studio</a>
                    <a href="tel:+7 (495) 123-45-67">+7 (495) 123-45-67</a>
                    <a href="#">г. Москва, ул. Пушкина, д. 10</a>
                </ContactInfo>
                <Copyright>
                &copy; {currentYear} Artell Studio.
                </Copyright>
            </FooterContent>
        </FooterContainer>
    );
};

export default Footer;