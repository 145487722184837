import styled from 'styled-components';
import { useState } from 'react';
import { Tags } from '../../dummy';

const Toggle = styled.div`
    height: 100%;
    width: 20px;
    background: rgb(245, 101, 12);
    right: 0;
    border-radius: 8px 0 0 8px;
    position: absolute;
    color: #1a1717;
    text-align: center;
    display: flex;
    font-weight: bold;
    font-size: 12px;
    writing-mode: tb;
    transform: rotate(180deg);
    cursor: pointer;
    justify-content: center;
`;

const PortfolioPanel = styled.div`
    position: absolute;
    top: 0;
    left: ${props => (props.isOpen ? '0' : '-45%')};
    width: 50%;
    height: 100%;
    background: rgb(38, 38, 38);
    color: #ffffff;
    border-radius: 0 8px 8px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    transition: left 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
`;

const FilterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: inherit;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    gap: 20px;
    align-items: flex-start;
`;

const FilterCheckbox = styled.div`
    display: flex;
    align-items: center;
    margin-right: 20px;

    input[type='checkbox'] {
        appearance: none;
        width: 18px;
        height: 18px;
        border: 2px solid;
        border-radius: 4px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: border-color 0.3s ease;

        &:before {
            content: '\\2713';
            color: transparent;
            font-size: 14px;
            transition: color 0.3s ease;
        }

        &:checked {
            border-color: #f56512;

            &:before {
                color: #f56512;
            }
        }

        &:hover {
            border-color: #f56512;
        }
    }
`;

const PortfolioPanelContent = ({ children, disabled = false, onClick = () => {}, isOpen }) => {
    const [selectedFilter, setSelectedFilter] = useState(null);

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
    };

    return (
        <PortfolioPanel isOpen={isOpen} disabled={disabled}>
            <Toggle onClick={!disabled ? onClick : () => {}}>{children}</Toggle>
            <FilterContainer>
                {Tags.map(tag => (
                    <RenderTag
                        key={tag.name}
                        tag={tag}
                        selectedFilter={selectedFilter}
                        handleFilterChange={handleFilterChange}
                    />
                ))}
            </FilterContainer>
        </PortfolioPanel>
    );
};

const RenderTag = ({ tag, selectedFilter, handleFilterChange }) => {
    const isSelected = selectedFilter === tag.name;

    return (
        <FilterCheckbox>
            <input
                type="checkbox"
                checked={isSelected}
                onChange={() => handleFilterChange(isSelected ? null : tag.name)}
            />
            {tag.name}
        </FilterCheckbox>
    );
};

export default PortfolioPanelContent;