import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from 'react-player/lazy'

const Video = () => {
    const [buffered, setBuffered] = useState(0);
    const playerRef = useRef(null);

    const handleProgress = (state) => {
        setBuffered(state.played);
    };

    return (
        <div className='video'>
            <ReactPlayer
                ref={playerRef}
                url="images/Post_final.mp4"
                controls
                width="50%"
                height="400px"
                playing={buffered > 0.8}
                onProgress={handleProgress}
                config={{
                    file: {
                        forcePreload: 'auto',
                        preload: 'auto'
                    }
                }}
            />

            <div className='vedeoBlock'>
                <h1 className='videoTitle'>3D видео</h1>

                <div className='videoText'>
                    <p className='text'>Использование 3D видео и анимации позволяет кратко, но максимально эффективно
                        передать самые сложные концепции, являясь непревзойденным маркетинговым инструментом. С помощью
                        высококачественной визуализации мы создаем уникальные идеи и запоминающиесы образы, которые
                        помогают привлечь внимание к Вашему продукту и услуге.</p>
                </div>
            </div>
        </div>
    )
}

export default Video