import React, {Fragment} from "react";
import useModal from "../../Hooks";
import { Close } from "../../Icons";


const ModalWindow = () => {
    const {closeModal, titleMod,descMod, urlMod} = useModal();

    return (
        <div className='modal'>
            <div onClick={() => closeModal()} className='eventPanel'><Close/></div>
            {!!titleMod && <div className='modalHeader'>{titleMod}</div>}
            {!!urlMod && <div className='modalImg'><img src={urlMod} alt=""/></div>}
            {!!descMod && <div className='modalDesc'>{descMod}</div>}
        </div>
    )
}


export default ModalWindow